import React from "react"
import Icon from "../Icon"
import Typography from "../Typography"
import { textBgClasses, textColorClasses } from '../theme'

const lightColors = ['light', 'lightest', 'white', 'neon']

const Header = ({ color, children, ...props }) => {
  const classes = [...(props.className||'').split(' '), 'flex', 'p-6', 'pl-7', 'gap-6']
  
  const bgColor = textBgClasses[color] ? color : 'black'
  classes.push(textBgClasses[bgColor])
  classes.push(lightColors.includes(bgColor) ? textColorClasses['black'] : textColorClasses['white'])
  
  const passingProps = {...props}
  delete passingProps.className
  delete passingProps.color
  delete passingProps.children

  return <header {...passingProps} className={classes.join(' ')}>
    <Icon name="logo" size="large"/>
    <Typography variant="sectionTitle" className="uppercase flex-grow" noMargin>{children}</Typography>
    <Icon name="bell"/>
    <Icon name="account"/>
  </header>
}

Header.defaultProps = {
  color: 'black',
}

export default Header
