import React from "react"
import { ReactComponent as Account } from "./svg/Account.svg"
import { ReactComponent as Add } from "./svg/Add.svg"
import { ReactComponent as Add_circle } from "./svg/Add_circle.svg"
import { ReactComponent as Arrow_back } from "./svg/Arrow_back.svg"
import { ReactComponent as Arrow_down } from "./svg/Arrow_down.svg"
import { ReactComponent as Arrow_Forward } from "./svg/Arrow_Forward.svg"
import { ReactComponent as Arrow_next } from "./svg/Arrow_next.svg"
import { ReactComponent as Arrow_Return } from "./svg/Arrow_Return.svg"
import { ReactComponent as Arrow_up } from "./svg/Arrow_up.svg"
import { ReactComponent as Bell } from "./svg/Bell.svg"
import { ReactComponent as Bookmark } from "./svg/Bookmark.svg"
import { ReactComponent as Bookmark_Filled } from "./svg/Bookmark_Filled.svg"
import { ReactComponent as Briefcase } from "./svg/Briefcase.svg"
import { ReactComponent as Calendar } from "./svg/Calendar.svg"
import { ReactComponent as Call_made } from "./svg/Call_made.svg"
import { ReactComponent as Call_received } from "./svg/Call_received.svg"
import { ReactComponent as Carrot_down } from "./svg/Carrot_down.svg"
import { ReactComponent as Chat } from "./svg/Chat.svg"
import { ReactComponent as Chat_alt } from "./svg/Chat_alt.svg"
import { ReactComponent as Checkbox } from "./svg/Checkbox.svg"
import { ReactComponent as Checkbox_Off } from "./svg/Checkbox_Off.svg"
import { ReactComponent as Checkmark } from "./svg/Checkmark.svg"
import { ReactComponent as Chevron_Back } from "./svg/Chevron_Back.svg"
import { ReactComponent as Chevron_Down } from "./svg/Chevron_Down.svg"
import { ReactComponent as Chevron_Next } from "./svg/Chevron_Next.svg"
import { ReactComponent as Chevron_Up } from "./svg/Chevron_Up.svg"
import { ReactComponent as Clear } from "./svg/Clear.svg"
import { ReactComponent as Create } from "./svg/Create.svg"
import { ReactComponent as Dollar } from "./svg/Dollar.svg"
import { ReactComponent as Download } from "./svg/Download.svg"
import { ReactComponent as Elipse_Horizontal } from "./svg/Elipse_Horizontal.svg"
import { ReactComponent as Eye } from "./svg/Eye.svg"
import { ReactComponent as Fast_forward } from "./svg/Fast_forward.svg"
import { ReactComponent as Fast_Rewind } from "./svg/Fast_Rewind.svg"
import { ReactComponent as Fine } from "./svg/Fine.svg"
import { ReactComponent as Hamburger } from "./svg/Hamburger.svg"
import { ReactComponent as Heart } from "./svg/Heart.svg"
import { ReactComponent as Heart_Stroke } from "./svg/Heart_Stroke.svg"
import { ReactComponent as Increase } from "./svg/Increase.svg"
import { ReactComponent as Info_outline } from "./svg/Info_outline.svg"
import { ReactComponent as Lightning } from "./svg/Lightning.svg"
import { ReactComponent as Logo } from "./svg/Logo.svg"
import { ReactComponent as Minus } from "./svg/Minus.svg"
import { ReactComponent as Print } from "./svg/Print.svg"
import { ReactComponent as Search } from "./svg/Search.svg"
import { ReactComponent as Share } from "./svg/Share.svg"
import { ReactComponent as Shirt } from "./svg/Shirt.svg"
import { ReactComponent as Star } from "./svg/Star.svg"
import { ReactComponent as Star_border } from "./svg/Star_border.svg"
import { ReactComponent as Stop } from "./svg/Stop.svg"
import { ReactComponent as Transport } from "./svg/Transport.svg"

import { ReactComponent as ColoredConstrain } from "./svg/colored/Constrain.svg"
import { ReactComponent as ColoredDecrease } from "./svg/colored/Decrease.svg"
import { ReactComponent as ColoredIncrease } from "./svg/colored/Increase.svg"
import { ReactComponent as ColoredReject } from "./svg/colored/Reject.svg"

const svgs = {
  account: Account,
  add: Add,
  add_circle: Add_circle,
  arrow_back: Arrow_back,
  arrow_down: Arrow_down,
  arrow_forward: Arrow_Forward,
  arrow_next: Arrow_next,
  arrow_return: Arrow_Return,
  arrow_up: Arrow_up,
  bell: Bell,
  bookmark: Bookmark,
  bookmark_filled: Bookmark_Filled,
  briefcase: Briefcase,
  calendar: Calendar,
  call_made: Call_made,
  call_received: Call_received,
  carrot_down: Carrot_down,
  chat: Chat,
  chat_alt: Chat_alt,
  checkbox: Checkbox,
  checkbox_off: Checkbox_Off,
  checkmark: Checkmark,
  chevron_back: Chevron_Back,
  chevron_down: Chevron_Down,
  chevron_next: Chevron_Next,
  chevron_up: Chevron_Up,
  clear: Clear,
  create: Create,
  dollar: Dollar,
  download: Download,
  elipse_horizontal: Elipse_Horizontal,
  eye: Eye,
  fast_forward: Fast_forward,
  fast_rewind: Fast_Rewind,
  fine: Fine,
  hamburger: Hamburger,
  heart: Heart,
  heart_stroke: Heart_Stroke,
  increase: Increase,
  info_outline: Info_outline,
  lightning: Lightning,
  logo: Logo,
  minus: Minus,
  print: Print,
  search: Search,
  share: Share,
  shirt: Shirt,
  star: Star,
  star_border: Star_border,
  stop: Stop,
  transport: Transport,

  constrain_colored: ColoredConstrain,
  decrease_colored: ColoredDecrease,
  increase_colored: ColoredIncrease,
  reject_colored: ColoredReject,
}

export default svgs
