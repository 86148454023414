import React from "react"
import svgs from "./svgs";
import { textColorClasses } from '../theme'

const Icon = ({ name, color, size, ...props }) => {
  const classes = [...(props.className||'').split(' '), 'fill-current', 'inline-block', 'object-cover', 'pt-0.5']

  if (textColorClasses[color]) {
    classes.push(textColorClasses[color])
  }

  // const sizeClasses = {
  //   'small': 'w-3 h-3',
  //   'medium': 'w-5 h-5',
  //   'large': 'w-7 h-7',
  // }
  // classes.push(sizeClasses[size])

  const sizeScales = {
    'small': 0.75,
    'medium': 1,
    'large': 1.5,
  }
  const scale = sizeScales[size]

  const passingProps = {...props}
  delete passingProps.name
  delete passingProps.color
  delete passingProps.size
  delete passingProps.className

  const IconSvg = svgs[name] || (() => <span>i</span>)
  return <span style={{transform: `scale(${scale})`}} {...passingProps} className={classes.join(' ')}><IconSvg /></span>
}

Icon.defaultProps = {
  // color: 'black',
  size: 'medium',
}

export default Icon
