import React, { useState } from 'react'
import { useLocation, Link } from "react-router-dom"
import Icon from "../Icon"

const MenuItem = ({ iconName, to, ...props }) => {
  const classes = [
    ...(props.className||'').split(' '),
    'cursor-pointer',
    'hover:text-black',
    'w-20',
    'h-14',
    'flex',
    'items-center',
    'justify-center',
    'box-border'
  ]

  const location = useLocation()
  // const [isActive, setIsActive] = useState(false)
  // setIsActive(location.pathname === to)
  const isActive = location.pathname === to

  classes.push(isActive ? 'text-black' : 'text-mid')
  if (isActive) {
    classes.push('border-l-3')
    classes.push('border-r-3')
    classes.push('border-black')
  }

  const passingProps = {...props}
  delete passingProps.className
  delete passingProps.iconName
  delete passingProps.to

  return (
    <Link to={to}>
      <li {...passingProps} className={classes.join(' ')} style={isActive ? {borderRightColor: 'transparent'} : {}}>
        <Icon name={iconName} />
      </li>
    </Link>
  );
};

MenuItem.defaultProps = {
}

export default MenuItem
