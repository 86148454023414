import React, { useEffect, useState } from 'react'
import Typography from '../Typography'
import Tabs from '../Tab/Tabs'
import ChartDonut from './ChartDonut'
import ChartLegend from './ChartLegend'
import { colorClasses } from '../theme'

// data: [{title, data: [{id, value}]} ]
const ChartCard = ({ data, isLandscape, valueMapper, title, ...props }) => {
  const classes = [...(props.className||'').split(' '), 'flex', 'flex-grow-0', 'w-min', 'h-full', 'bg-white', 'border-1', 'border-line', 'px-5', 'py-4']

  classes.push(isLandscape ? 'flex-row' : 'flex-col')

  const passingProps = {...props}
  delete passingProps.className
  delete passingProps.data
  delete passingProps.valueMapper
  delete passingProps.title

  const [activeTab, setActiveTab] = useState(data[0])

  return <div className={classes.join(' ')+' gap-4'}>
    <div className="w-80">
      {title && <Typography variant="sectionTitle" className="uppercase">{title}</Typography>}
      <Tabs tabs={data} onClick={(tab) => setActiveTab(tab)} />
      <ChartDonut
        data={activeTab.data}
        totalValue={activeTab.totalValue}
        totalLabel={activeTab.totalLabel}
        valueMapper={valueMapper}
        {...passingProps} />
    </div>
    <div className={`bg-line
      ${isLandscape ? 'w-px' : 'w-80 h-px'}
      `}></div>
    <ChartLegend data={activeTab.data} valueMapper={valueMapper} className="w-80 self-center" />
  </div>
}

ChartCard.defaultProps = {
  isLandscape: false,
  data: [],
}

export default ChartCard
