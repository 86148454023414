import React from "react"
import { textColorClasses, fontClasses } from '../theme'

const Typography = ({ variant, component, children, color, noMargin, ...props }) => {
  const Component = component || props.variantComponents[variant] || 'p'
  const classes = [...(props.className||'').split(' ')]

  const fontFamily = Component == 'span' ? 'adihausDin' : ( Component == 'p' ? 'adineueText' : 'adineuePro' )
  classes.push(fontClasses[fontFamily])

  if (textColorClasses[color]) {
    classes.push(textColorClasses[color])
  }

  const variantSizes = {
    pageTitle: 'text-4xl',
    sectionTitle: 'text-2xl',
    subTitle: 'text-base',
    body: 'text-base',
    h1: 'text-4xl',
    h2: 'text-3xl',
    h3: 'text-2xl',
    h4: 'text-xl',
    h5: 'text-lg',
    h6: 'text-base',
  }
  classes.push(variantSizes[variant])
  // else if (component === "subtitle1") typograghyCss += "text-base font-bold leading-5 ";
  // else if (component === "subtitle2") typograghyCss += "text-sm font-bold leading-5 ";
  // else if (component === "body1")
  //   typograghyCss += "text-base leading-5 font-normal";
  // else if (component === "body2")
  //   typograghyCss += "text-sm leading-5 font-thin";
  //   
  if (variant === 'subTitle') {
    classes.push('font-bold')
  }

  if (!noMargin) {
    const variantMargins = {
      pageTitle: 'mb-7',
      sectionTitle: 'mb-5',
      subTitle: 'mb-0',
      body: 'mb-0',
      h1: 'mb-7',
      h2: 'mb-6',
      h3: 'mb-5',
      h4: 'mb-4',
      h5: 'mb-3',
      h6: 'mb-0',
    }
    classes.push(variantMargins[variant])
  }


  const passingProps = {...props}
  delete passingProps.variant
  delete passingProps.component
  delete passingProps.color
  delete passingProps.children
  delete passingProps.variantComponents
  delete passingProps.className

  return <Component {...passingProps} className={classes.join(' ')}>{children}</Component>
}

Typography.defaultProps = {
  variant: 'body',
  // color: 'black',
  variantComponents: {
    pageTitle: 'h1',
    sectionTitle: 'h2',
    subTitle: 'span',
    body: 'p',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
  }
}

export default Typography


/*
// page title 
font-family: adineue PRO;
font-weight: bold;
font-size: 40px;

// section title 
font-family: adineue PRO;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 24px;

// Subtitles/S1 • 16 • Bold • AdihausDin 
font-family: AdihausDIN;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;

// Body/B1 • 16 • AdiText • Reg 
font-family: adineue TEXT;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;

*/