import React from "react"

const Menu = ({ children, ...props }) => {
  const classes = [...(props.className||'').split(' '), 'my-6']

  const passingProps = {...props}
  delete passingProps.className
  delete passingProps.children

  return <ul {...passingProps} className={classes.join(' ')}>{children}</ul>
}

export default Menu
