import React from "react"
import Typography from '../Typography'
import { chartColors } from '../theme'

const chartColorValues = Object.values(chartColors)
const ChartLegend = ({ data, valueMapper, ...props }) => {
  const classes = [...(props.className||'').split(' '), 'flex', 'flex-col', 'gap-4', 'max-w-xs']

  const passingProps = {...props}
  delete passingProps.className
  delete passingProps.data
  delete passingProps.valueMapper

  return <ul {...passingProps} className={classes.join(' ')}>
    {data.map((item, i) => <li key={item.id} className="flex gap-4 align-center">
      <div className="w-6 h-6 rounded-md" style={{backgroundColor: chartColorValues[i]}}></div>
      <Typography variant="body" className="flex-grow">{item.id}</Typography>
      <Typography variant="subTitle">{valueMapper ? valueMapper(item.value) : item.value}</Typography>
    </li>)}
  </ul>
}

ChartLegend.defaultProps = {
}

export default ChartLegend
