import React, { useEffect, useState } from 'react'

const Tabs = ({ tabs, onClick, children, ...props }) => {
  const classes = [...(props.className||'').split(' '), 'flex', 'justify-around', 'text-center']
  const passingProps = {...props}
  delete passingProps.className
  delete passingProps.tabs
  delete passingProps.onClick
  delete passingProps.children

  const [activeTab, setActiveTab] = useState(tabs[0])

  return (
    <ul {...passingProps} className={classes.join(' ')}>
      {tabs.map((tab, i) => <li key={i}
        className={`cursor-pointer hover:bg-mid flex-grow border-black border-t-1 border-b-1 border-r-1 transition-all text-sm
        ${i==0 ? 'border-l-1' : ''}
        ${activeTab==tab ? 'bg-black text-white' : ''}
        `}
        onClick={() => {
          setActiveTab(tab)
          if (typeof onClick === 'function') {
            onClick(tab, i, tabs)
          }
        }}
        >{tab.title}</li>)}
      {children}
    </ul>
  )
}

Tabs.defaultProps = {
  tabs: [],
}

export default Tabs
