import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Header,
  Menu,
  MenuItem,
  ChartCard,
  Typography,
} from '@annutdd/adidas-react-components'

const pages = {
  '/': {
    title: 'Financials', iconName: 'dollar', portrait: 1, landscape: 1, cardTitle: 'demand distribution',
    tabs: ['Gender', 'APMC', 'Division', 'Channel', 'KCC'],
    names: ['Men', 'Women', 'Infant', 'Unisex'],
    values: [14, 12, 10, 6], suffix: 'M', totalLabel: 'Total'
  },
  '/portfolio': {
    title: 'Portfolio', iconName: 'briefcase', landscape: 1, cardTitle: 'demand distribution',
    tabs: ['Gender', 'APMC', 'Division', 'Channel', 'KCC'],
    names: ['Full Price', 'Yeezy', 'Marketing Partnership', 'CRM', 'Sales'],
    values: [16.6, 7.4, 3.7, 3.7, 1.85], suffix: 'M', totalLabel: 'Total'
  },
  '/rdp': {
    title: 'RDP', iconName: 'star_border', portrait: 1, cardTitle: 'Quarterly figures',
    tabs: ['Q1', 'Q2', 'Q3', 'Q4'],
    names: ['Germany', 'Netherlands', 'Poland', 'UK', 'France'],
    values: [80, 45, 32, 28, 10], suffix: 'K', totalLabel: 'Global'
  },
  '/campaigns': {
    title: 'Campaigns', iconName: 'calendar', landscape: 1, cardTitle: 'yearly figures',
    tabs: ['2021', '2020', '2019'],
    names: ['Germany', 'Netherlands', 'Poland', 'UK', 'France'],
    values: [80, 45, 32, 28, 10], suffix: 'K', totalLabel: 'Global'
  },
}
const nextSiffix = {K: 'M', M: 'B'}

// generate and add data properties with random values for each tab
Object.values(pages).forEach(page => {
  page.mapper = v => v + page.suffix
  // data = [{title, data: [{id, value}], totalValue, totalLabel} ]
  page.data = page.tabs.map((tabName, t) => {
    var total = 0
    const addToTotal = n => { total += n; return n}
    return {
      title: tabName,
      data: page.names.map((labelName, di) => {
        const v = page.values[di]
        return {
          id: labelName,
          value: addToTotal(t === 0 ? v : +(Math.round(v + v * (Math.random() - 0.5)).toFixed(2))) // up to +/- 0.5x change
        }
      }),
      totalLabel: page.totalLabel,
      totalValue: '$'+(total > 1000 ? Math.round(total/1000)+nextSiffix[page.suffix] : (total+page.suffix))
    }
  })
})

const Page = () => {
  const location = useLocation()
  const [activePage, setActivePage] = useState(pages['/'])
  useEffect(() => {
    setActivePage(pages[location.pathname])
  }, [location])

  return (
    <div className="flex flex-col min-h-screen">
      <Header>Trading Cockpit</Header>
      <div className="flex flex-grow">
        <aside className="bg-white border-r-1 border-line">
          <Menu>{Object.entries(pages).map(([to, page]) =>
            <MenuItem key={to} to={to} iconName={page.iconName} />
          )}</Menu>
        </aside>
        <main className="flex-grow p-10">
          <Typography variant="pageTitle" className="uppercase">{activePage.title}</Typography>
          <div className="flex gap-10">
            { activePage.portrait && 
              <ChartCard data={activePage.data} valueMapper={activePage.mapper} title={activePage.cardTitle} interactive />}
            { activePage.landscape &&
              <ChartCard data={activePage.data} valueMapper={activePage.mapper} title={activePage.cardTitle} isLandscape interactive />}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Page;
