export const fontClasses = {
  adineueText: 'font-adineueText',
  adineuePro: 'font-adineuePro',
  adihausDin: 'font-adihausDin',
}

export const textColorClasses = {
  black: 'text-black',
  darker: 'text-darker',
  dark: 'text-dark',
  mid: 'text-mid',
  line: 'text-line',
  light: 'text-light',
  lightest: 'text-lightest',
  white: 'text-white',
  fuchsia: 'text-fuchsia',
  red: 'text-red',
  green: 'text-green',
  neon: 'text-neon',
  blue: 'text-blue',
  purple: 'text-purple',
  orange: 'text-orange'
}

export const textBgClasses = {
  black: 'bg-black',
  darker: 'bg-darker',
  dark: 'bg-dark',
  mid: 'bg-mid',
  line: 'bg-line',
  light: 'bg-light',
  lightest: 'bg-lightest',
  white: 'bg-white',
  fuchsia: 'bg-fuchsia',
  red: 'bg-red',
  green: 'bg-green',
  neon: 'bg-neon',
  blue: 'bg-blue',
  purple: 'bg-purple',
  orange: 'bg-orange'
}

export const chartColors = {
  purple: "#951DAC",
  orange: "#FA9610",
  fuchsia: "#DA2262",
  green: "#88BB5A",
  blue: "#2E87A8",
  red: "#DF2626",
  neon: "#EAE32F",
}

export const colors = {
  black: "#000000",
  darker: "#767677",
  dark: "#9A9A9B",
  mid: "#C4C4C4",
  line: "#DADBDF",
  light: "#EBEDEE",
  lightest: "#FAFAFA",
  white: "#FFFFFF",
  fuchsia: "#DA2262",
  red: "#DF2626",
  green: "#88BB5A",
  neon: "#EAE32F",
  blue: "#2E87A8",
  purple: "#951DAC",
  orange: "#FA9610"
}
