import React from "react"
import { ResponsivePie } from '@nivo/pie'
import { chartColors } from '../theme'
import Typography from "../Typography"

const ChartDonut = ({
  data,
  size,
  hoverArcGrowth,
  interactive,
  labels,
  valuesOnArcs,
  valueMapper,
  linkThickness,
  linkColor,
  totalValue,
  totalLabel,
  ...props }) => {

  const labelMargin = labels ? 30 : 0
  const growthMargin = interactive ? hoverArcGrowth : 0
  const bounceAnimationMargin = 10
  const margin = labelMargin + growthMargin + bounceAnimationMargin

  const passingProps = {...props}
  // delete passingProps.className
  delete passingProps.data
  delete passingProps.size
  delete passingProps.hoverArcGrowth
  delete passingProps.interactive
  delete passingProps.labels
  delete passingProps.valuesOnArcs
  delete passingProps.linkThickness
  delete passingProps.linkColor
  delete passingProps.valueMapper
  delete passingProps.totalValue
  delete passingProps.totalLabel

  return <div {...passingProps} style={{position: 'relative', height: size+margin*2}} >
    <div className="absolute inset-0 flex flex-col justify-center text-center">
      {totalValue && <Typography noMargin variant="h5">{totalValue}</Typography>}
      {totalLabel && <Typography noMargin variant="body">{<span className="text-xs text-dark">{totalLabel}</span>}</Typography>}
    </div>
    <ResponsivePie
      colors={Object.values(chartColors)}
      innerRadius={0.8}
      padAngle={3}
      cornerRadius={6}
      margin={{ top: margin, right: margin, bottom: margin, left: margin }}
      // borderWidth={0}
      arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'brighter', 8 ] ] }}
      arcLinkLabelsTextColor={'#333333'}

      arcLabelsSkipAngle={10}
      arcLinkLabelsSkipAngle={10}

      data={data}
      isInteractive={!!interactive}
      enableArcLinkLabels={!!labels}
      enableArcLabels={!!valuesOnArcs}
      arcLinkLabelsThickness={linkThickness}
      arcLinkLabelsColor={linkColor || { from: 'color' }}
      activeOuterRadiusOffset={hoverArcGrowth}
    />
  </div>
}

ChartDonut.defaultProps = {
  data: [],
  size: 200,
  labels: false,
  interactive: false,
  valuesOnArcs: false,
  linkThickness: 1,
  hoverArcGrowth: 10,
}

export default ChartDonut
